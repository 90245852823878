/* Define custom theme colors */
:root {
    --theme-background: #f9fafb;
    --theme-text: #1f2937;
    --theme-text-muted: #6b7280;
    --theme-heading: #111827;
    /* --theme-primary: hsl(173.05, 89%, 34%);  */
    --theme-primary: #05564d; /* Teal */
    --theme-primary-dark--22: #0f766e; /* Darker teal */
    --theme-primary-dark: #033f38; /* Darker teal */
    --theme-secondary: #10b981; /* Green */
    --theme-secondary-dark: #059669; /* Darker green */
    --theme-accent: #f59e0b; /* Accent yellow */
    --theme-accent-1:  #D32F2F; /* Accent yellow */
    --theme-title:  #111827;
    --theme-dark-text: #152941CC;
    
}

/* Apply theme colors */
body {
    background-color: var(--theme-background);
    color: var(--theme-text);
}

.bg-theme-primary{
    background-color: var(--theme-secondary);
}

/* Custom gradients */
.bg-theme-primary-gradient {
    background: linear-gradient(135deg, var(--theme-primary), var(--theme-secondary));
}

/* Custom button styles */
a:hover {
    transition: all 0.3s ease;
}

a.bg-theme-primary:hover {
    background-color: var(--theme-primary-dark);
}

a.border-theme-primary:hover {
    background-color: var(--theme-primary);
    color: #fff;
}

/* Typography improvements */
h1 {
    color: var(--theme-heading);
}

p {
    color: var(--theme-text-muted);
}

nav a {
    font-weight: 600;
}

/* Responsive Image Styles */
img {
    transition: transform 0.3s ease;
}

img:hover {
    transform: scale(1.05);
}

/* Utility Classes */
.shadow-md {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.rounded-lg {
    border-radius: 12px;
}

/* Miscellaneous */
a {
    text-decoration: none;
    font-weight: 500;
    border-radius: 8px;
}

.container {
    max-width: 1200px;
}

.db{
    border-radius: 4px;
}

body {
    --bs-body-font-family: var(--bs-font-monospace);
    --bs-body-line-height: 1.4;
    --bs-body-bg: var(--bs-gray-100);
}

body {
    overflow-x: hidden; /* This prevents horizontal scrolling */
}

img, iframe {
    max-width: 100%; /* Ensures that images and iframes don't exceed the width of their container */
}

/* .container {
    width: 100%;
    max-width: 100%;
} */

* {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.table {
    --bs-table-color: var(--bs-gray-600);
    --bs-table-bg: var(--bs-gray-100);
    --bs-table-border-color: transparent;
}

.component {
    color: var(--bs-gray-800);
    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    border-radius: 0.25rem;
}

.component-header {
    color: var(--bs-purple);
}

.round-btn-ixon {
    width: 40px !important;
    height: 40px !important;
}

.cst-header {
    -webkit-box-shadow: -1px 1px 9px 2px rgba(148, 148, 148, 1);
    -moz-box-shadow: -1px 1px 9px 2px rgba(148, 148, 148, 1);
    box-shadow: -1px 1px 9px 2px rgba(148, 148, 148, 1);
}

.cst-header.header-shadow {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.cst-footer {
    /* background-image: url("../images/footer-bg.jpg"); */
    background-color: white;
    background-size: cover;
    background-position: bottom;
    /* color: white; */
}

.cst-copyright {
    /* background-color: purple;
    color: white; */
}

.cst-link:hover {
    color: rgb(110, 110, 110) !important;
}

.brand-logo {
    width: 250px !important;
}
.cst-heroslider {
    /* min-height: 500px; */
}
.cst-s-i {
    height: 900px;
}

.cst-s-i > img {
    padding: 0 300px;
}

.social-link {
    background-color: #b9b9b9; /* Facebook blue background color */
    color: white; /* Text color */
    transition:
        background-color 0.3s ease,
        color 0.3s ease; /* Add smooth transition effect */
    margin-right: 10px; /* Add some margin between buttons */
    width: 40px; /* Set a fixed width for circular buttons */
    height: 40px; /* Set a fixed height for circular buttons */
    justify-content: center;
    align-items: center;
    border: none; /* Remove button border */
    border-radius: 50%; /* Make the button circular */
}

.social-link:hover {
    background-color: #949494; /* Darker Facebook blue on hover */
    color: white; /* Text color remains white on hover */
    animation: buttonAnimation 1s normal; /* Apply the animation on hover */
}

@keyframes buttonAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.bg-jumbotron {
    background: rgb(2, 0, 36);
    background: radial-gradient(
        circle,
        rgba(2, 0, 36, 1) 0%,
        rgba(4, 1, 44, 1) 54%,
        rgba(19, 9, 121, 1) 76%,
        rgba(7, 3, 62, 1) 84%,
        rgba(1, 204, 249, 1) 99%,
        rgba(0, 212, 255, 1) 100%
    );
}

/* header */
/* Reduce the overall size of the logo */
.brand-logo {
    transition: transform 0.2s ease-in-out;
}

.brand-logo:hover {
    transform: scale(1.05);
}

/* Minimalist style for brand text */
.brand-text {
    font-size: 1.2rem;
    font-weight: 500;
    color: #333;
}

/* Simplified nav-link style */
.nav-link {
    font-size: 0.9rem;
    color: #555;
    transition: color 0.3s ease;
}

.nav-link:hover {
    color: #007bff;
}

/* Minimalist button styles */
.btn-outline-secondary {
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    transition:
        background-color 0.3s ease,
        color 0.3s ease;
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-outline-primary {
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    transition:
        background-color 0.3s ease,
        color 0.3s ease;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

/* header end */
.cst-s-i {
    position: relative;
    height: 100vh; /* Set height to full viewport height */
    width: 100%; /* Ensure full width */
}

.fullscreen-image {
    height: 100%; /* Ensure image covers the full height of parent */
    width: 100%; /* Ensure image covers the full width of parent */
    object-fit: cover; /* Maintain aspect ratio and cover entire space */
}
/* constact us */
.contact-page {
    background: #f8f9fa;
}

.contact-form-container {
    background: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form-container .card {
    border: none;
}

.contact-form-container .btn {
    width: 100%;
}

.jumbotron {
    background-image: url("../images/waves-8236919_1280.jpg");
    background-size: cover;
    background-position: center;
}

.jumbotron h1,
.jumbotron .breadcrumb-item a,
.jumbotron .breadcrumb-item.active {
    color: #fff;
}

.form-control {
    border-radius: 0.25rem;
}

.form-control:focus {
    box-shadow: none;
    border-color: #007bff;
}
.contact-page {
    background: #f8f9fa;
}

.contact-form-container {
    background: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form-container .card {
    border: none;
}

.contact-form-container .btn {
    width: 100%;
}

.carousel-container {
    max-width: 100%;
    overflow: hidden;
}

.carousel-slide img {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
}

.legend {
    background-color: rgba(
        0,
        0,
        0,
        0.5
    ); /* Optional: Add background color for better text visibility */
    color: #fff;
    padding: 10px;
}

/* Global styles */
body {
    margin: 0;
    font-family: 'Montserrat', sans-serif !important;
}

.signup-container {
    display: flex;
    height: 100vh;
}

.quote-section {
    background-size: cover;
    background-position: center;
    height: 100%;
    position: relative;
    color: #fff;
    text-align: center;
}

.quote-overlay {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
    max-width: 80%;
    margin: 20px;
}

.quote-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.quote-text {
    font-size: 18px;
    font-style: italic;
}

.quote-author {
    font-size: 16px;
    margin-top: 10px;
}

.signup-form-section {
    background: #f8f9fa;
    border-radius: 0 20px 20px 0;
    height: 100%;
}

.signup-form {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-primary {
    background: #007bff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    color: #fff;
}

.btn-primary:hover {
    background: #0056b3;
}

.form-label {
    font-weight: bold;
}

.text-center {
    text-align: center;
}

.text-decoration-none {
    text-decoration: none;
}

.text-decoration-none:hover {
    text-decoration: underline;
}

.social-icons .btn {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    font-size: 18px;
    color: #333;
}

.social-link i {
    color: #333;
}

@media (max-width: 768px) {
    .quote-section {
        display: none;
    }

    .signup-form-section {
        border-radius: 0;
    }
}

@media (min-width: 768px) {
    .social-icons {
        display: flex;
        justify-content: center;
    }
}

/* Carousel Styles */
.carousel-container .carousel-slide {
    position: relative;
}

.carousel-container .carousel-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-container .legend {
    color: #fff;
    font-size: 1.5rem;
    text-align: center;
    padding: 0 1rem;
}

/* Card Styles */
.card {
    transition:
        transform 0.3s ease,
        box-shadow 0.3s ease;
}

.card:hover {
    /* transform: translateY(-10px); */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.card-img-top {
    border-bottom: 2px solid #ddd;
}

.card-body {
    background: #fff;
}

/* About Section */
.tm-about-area {
    /* background-image: url("../images/pexels-knownasovan-62693.jpg"); */
    background-size: cover;
    background-position: center;
}

.tm-about-content {
    background: rgba(255, 255, 255, 0.9);
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Blog Section */
.tm-blog-area .card {
    border-radius: 8px;
    overflow: hidden;
}

.tm-blog-area .card-img-top {
    transition: transform 0.3s ease;
}

.tm-blog-area .card-img-top:hover {
    transform: scale(1.05);
}

/* Add this CSS to your stylesheet */
.carousel-container {
    height: 93vh;
}

.carousel-slide img {
    height: 93vh;
    object-fit: cover;
}

.carousel-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3); /* Optional: to make text more readable */
}

.legend {
    color: white; /* Ensure the text is visible */
}

/* Login form container should be on top of everything on the right */
.login-form-container {
  z-index: 2;
  position: relative;
  background-color: #fff;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}
.overlay {
    position: absolute;
    background-color:'linear-gradient(135deg, rgba(60, 179, 113, 0.8), rgba(2, 2, 36, 0.8))';
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    border-radius: 15px;
    /* Apply only to the left column */                                     
  }
.oauth-buttons a {
  padding: 10px 20px;
  transition: all 0.3s ease;
}

.oauth-buttons a:hover {
  background-color: #eee;
}
.col-md-6.image-section {
    position: relative;
    /* Ensure the image section has relative positioning to contain the overlay */
  }

  /* Custom Toast Styles */
.custom-toast {
    background: linear-gradient(135deg, #00BFFF, #87CEEB);
    color: #fff;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 14px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .custom-toast .toast-body {
    display: flex;
    align-items: center;
  }
  
  .custom-toast .toast-close-button {
    color: #fff;
    opacity: 0.8;
  }
  
  .custom-toast .toast-close-button:hover {
    opacity: 1;
  }

  /* sign up */
  .signup-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .signup-form {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  .form-label {
    font-weight: bold;
  }
  
  .btn-primary {
    background-color: #2575fc;
    border: none;
  }
  
  .btn-primary:hover {
    background-color: #1d68c4;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
  }
  
  .social-link {
    font-size: 1.5rem;
    margin: 0 5px;
  }
  
  .social-link i {
    color: #333;
  }

  .btn-red {
    background-color: var(--theme-title); /* Bright red background */
    color: #fff; /* White text */
    border: none; /* No border */
    padding: 10px 20px; /* Padding inside the button */
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Font size */
    font-weight: bold; /* Bold text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  }
  
  .btn-red:hover {
    color: white;
    background-color: #C62828; /* Slightly darker red on hover */
    transform: scale(1.05); /* Slightly increase the size on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
  }
  
  .btn-red:active {
    background-color: #B71C1C; /* Even darker red when clicked */
    transform: scale(0.98); /* Slightly shrink on click */
    box-shadow: none; /* Remove shadow on click */
  }
  
  .nav-link{
    font-weight: 700;
    color: #252525;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif; /* Added font family */
  }
  
  .nav-link:hover{
    color: #707070;
  }

.hero-title{
    color: white;
    font-size: 40px;
    font-weight: 700;
}


.carousel-slide {
    position: relative;
  }
  
  .carousel-overlay {
    position: absolute;
    top: 90%;
    width: 100%;
    text-align: center;
  }
  
  .hero-box {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.85), rgba(245, 245, 245, 0.85)); 
    padding: 30px; 
    max-width: 500px; 
    border: 2px solid var(--theme-title); 
    margin-left: auto; 
    margin-right: 20%; 
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15), 
              inset 0 0 0 4px rgba(255, 255, 255, 0.8); 
    position: relative;
    overflow: hidden; 
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
  }

  .hero-title {
    font-family: 'Roboto', sans-serif; 
    color: var(--theme-title); 
    text-align: right; 
    text-transform: uppercase; 
  }
  
  .hero-subtitle {
    display: block;
    font-size: 20px;
    color: #333;
    margin-top: 10px;
    text-align: right;
  }

  .btn-outline-red {
    background-color: transparent;
    color: var(--theme-title); /* Red text */
    border: 2px solid var(--theme-title);
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .btn-outline-red:hover {
    background-color: var(--theme-title);
    color: #fff;
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .feature-icon {
    display: block;
    margin-bottom: 15px; /* Space between image and text */
  }
  
  .feature-title {
    font-size: 1.25rem;
    margin-bottom: 10px;
    color: var(--theme-title); /* Matching red theme color */
  }
  
  .feature-text {
    font-size: 1rem;
    color: #333;
    margin: 0 auto;
    max-width: 80%; /* To control the width of the text */
  }
  
  .tm-feature-area {
    padding: 3rem 0; /* Extra padding for spacing */
  }
  
  .tm-section {
    background-color: #ffffff !important; /* Light background for the section */
  }
  
  .text-center {
    text-align: center;
  }
  .section-3{
    background-color: #f7f7f7;
  }
  .section-4{
    background-color: #ffffff
  }
  .sec-feature-title{
    font-size: 2.55rem;
    margin-bottom: 10px;
    color: var(--theme-title); /* Matching red theme color */ 
  }
  .section-5{
    background-color: var(--theme-title);
  }
  .tw{
    color: #ffffff !important;
  }

.btn-sec{
    background-color: var(--theme-primary);
    border-radius: 0px;
    color: white;
}

.btn-sec:hover{
    background-color: var(--theme-primary-dark);
    color: white;
}

.btn-c{
    border-radius: 4px; 
} 

.cw{
    color: #ffffff !important;
}

.btn-out-sec {    
    border-radius: 0px;
    /* border: 1px solid #008080;   */
    background-color: transparent; /* Transparent background for subtlety */
    color: #008080;             /* Match text color to the border */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition effect */
}

.btn-out-sec:hover {
    background-color: #008080;  /* Change background on hover for interactivity */
    color: white;               /* White text on hover for contrast */
}

/* Base Styles */
body {
    font-family: 'Inter', sans-serif; /* Use a clean, modern sans-serif font */
    background-color: #F7F8FA; /* Light background for better readability */
    color: #333333; /* Dark text for high contrast */
    line-height: 1.6; /* Standard line height for readability */
    margin: 0;
  }
  
  /* Headings */
  h1 {
    font-size: 3rem; /* Large headline for main sections */
    font-weight: 700; /* Bold for emphasis */
    color: #2F80ED; /* Primary brand color */
    margin-bottom: 1rem; /* Space below */
  }
  
  h2 {
    font-size: 2rem; /* Slightly smaller for subsections */
    font-weight: 600; /* Semi-bold */
    color: #333333; /* Dark color for high contrast */
    margin-bottom: 0.75rem; /* Space below */
  }
  
  h3 {
    font-size: 1.5rem; /* For section titles */
    font-weight: 600; /* Semi-bold */
    color: #2F80ED; /* Primary brand color */
    margin-bottom: 0.5rem; /* Space below */
  }
  
  /* Body Text */
  p {
    font-size: 1rem; /* Standard text size */
    font-weight: 400; /* Regular weight */
    color: #333333; /* Dark text for readability */
    margin-bottom: 1rem; /* Space below paragraphs */
  }
  
  /* Links */
  a {
    color: #2F80ED; /* Primary brand color for links */
    text-decoration: none; /* Remove underline for links */
    transition: color 0.3s ease; /* Smooth transition for hover effects */
  }
  
  a:hover {
    color: #1C6DC9; /* Darker shade for hover effect */
  }
  
  /* Buttons */
  button {
    font-family: 'Inter', sans-serif; /* Match button font to body */
    font-size: 1rem; /* Standard button size */
    font-weight: 600; /* Semi-bold for emphasis */    
    color: #FFFFFF; /* White text */
    padding: 12px 24px; /* Padding for comfort */
    border: none; /* Remove default border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition */
  }
  
  button:hover {
    background-color: #1C6DC9; /* Darker shade on hover */
  }
  
  /* Utility Classes */
  .text-center {
    text-align: center; /* Center text utility */
  }
  
  .mb-1 {
    margin-bottom: 0.25rem; /* Small bottom margin */  
  }
  
  .mb-2 {
    margin-bottom: 0.5rem; /* Medium bottom margin */
  }
  
  .mb-4 {
    margin-bottom: 1rem; /* Large bottom margin */
  }

.bg-white{
    background: white !important;
}

.txt-dark{
    color: var(--theme-dark-text) !important;
}

/* 
  .accordion-button {
    transition: background-color 0.3s ease;
  } */

  /* .accordion-button:hover {
    background-color: hsl(180, 100%, 45%);
  } */