.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2; /* Background color for the entire container */
    padding: 20px;
}

.signup-content {
    display: flex;
    max-width: 1200px;
    background-color: #ffffff; /* Background color for the content box */
    border-radius: 10px;
    overflow: hidden;
}

.card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
}

.quote-section {
    flex: 1;
    position: relative;
    height: 735px;
    width: 600px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.quote-overlay {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)); /* Gradient overlay */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.quote-text {
    text-align: center;
    color: #ffffff;
    padding: 1rem;
}

.quote-text h4 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.quote-text p {
    font-size: 1.2rem;
    line-height: 1.6;
}

.signup-form-section {
    flex: 1;
    padding: 40px;
}

.signup-form {
    background-color: #ffffff; /* Background color for the form */
    /* border-radius: 8px; */
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);  */
    padding: 20px;
}

.signup-form h3 {
    font-size: 1.5rem;
    color: #333333;
}

.form-label {
    font-size: 1rem;
    color: #555555;
}

.form-control {
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 10px;
    font-size: 1rem;
}

.btn-primary {
    background-color: #007bff;
    border: none;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.social-icons .btn {
    color: #007bff;
    border: 1px solid #007bff;
    background-color: transparent;
    margin: 0 5px;
}

.social-icons .btn:hover {
    color: #0056b3;
    border-color: #0056b3;
}

.text-decoration-none {
    text-decoration: none;
    color: #007bff;
}

.text-decoration-none:hover {
    text-decoration: underline;
}

.btn-custom {
    background-color: #007bff; /* Custom color for the button */
    border: none;
    color: #ffffff;
    width: 100%; /* Make the button full-width */
    padding: 10px;
    font-size: 1rem;
    border-radius: 4px;
}

.btn-custom:hover {
    background-color: #0465cd; /* Custom hover color */
    color: #ffffff;
}